.gridjs {
  &-head {
    width: 100%;
    margin-bottom: 5px;
    padding: 5px 1px;

    &::after {
      content: '';
      display: block;
      clear: both;
    }
  }

  &-head:empty {
    padding: 0;
    border: none;
  }
}
