@import 'colors';

.gridjs-pagination {
  color: $gray6;

  &::after {
    content: '';
    display: block;
    clear: both;
  }

  .gridjs-summary {
    float: left;
    margin-top: 5px;
  }

  .gridjs-pages {
    float: right;

    button {
      padding: 5px 14px;
      border: 1px solid $gray4;
      background-color: $white;
      border-right: none;
      outline: none;
      user-select: none;
    }

    button:focus {
      box-shadow: 0 0 0 2px rgba(149, 189, 243, 0.5);
    }

    button:hover {
      background-color: $gray2;
      color: $darkBlue1;
      outline: none;
    }

    button:disabled,
    button[disabled],
    button:hover:disabled {
      cursor: default;
      background-color: $white;
      color: $gray5;
    }

    button.gridjs-spread {
      cursor: default;
      box-shadow: none;
      background-color: $white;
    }

    button.gridjs-currentPage {
      background-color: $gray2;
      font-weight: bold;
    }

    button:last-child {
      border-bottom-right-radius: 6px;
      border-top-right-radius: 6px;
      border-right: 1px solid $gray4;
    }

    button:first-child {
      border-bottom-left-radius: 6px;
      border-top-left-radius: 6px;
    }
  }
}
