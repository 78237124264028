table.gridjs {
  &-table {
    max-width: 100%;
    border-collapse: collapse;
    text-align: left;
    display: table;
    margin: 0;
    padding: 0;
    overflow: auto;
    table-layout: fixed;
  }
}
