@import 'colors';

.gridjs {
  &-resizable {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 5px;

    &:hover {
      cursor: ew-resize;
      background-color: $blue1;
    }
  }
}
