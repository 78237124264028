.choices {
  margin-bottom: 1.5rem;
  font-size: 1rem;
  position: relative;
  overflow: hidden;
}

.choices:focus {
  outline: none;
}

.choices:last-child {
  margin-bottom: 0;
}

.choices.is-open {
  overflow: visible;
}

.choices.is-disabled .choices__inner, .choices.is-disabled .choices__input {
  cursor: not-allowed;
  -webkit-user-select: none;
  user-select: none;
  background-color: #fff;
}

.choices.is-disabled .choices__item {
  cursor: not-allowed;
}

.choices [hidden] {
  display: none !important;
}

.choices[data-type*="select-one"] {
  cursor: pointer;
}

.choices[data-type*="select-one"] .choices__inner {
  padding-bottom: .4688rem;
}

.choices[data-type*="select-one"] .choices__input {
  background-color: #fff;
  border-bottom: .0625rem solid #0000;
  width: 100%;
  margin: 0;
  padding: .625rem;
  display: block;
}

.choices[data-type*="select-one"] .choices__button {
  opacity: .25;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMjEiIHZpZXdCb3g9IjAgMCAyMSAyMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSIjMDAwIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0yLjU5Mi4wNDRsMTguMzY0IDE4LjM2NC0yLjU0OCAyLjU0OEwuMDQ0IDIuNTkyeiIvPjxwYXRoIGQ9Ik0wIDE4LjM2NEwxOC4zNjQgMGwyLjU0OCAyLjU0OEwyLjU0OCAyMC45MTJ6Ii8+PC9nPjwvc3ZnPg==");
  background-size: .5rem;
  border-radius: 10em;
  width: 1.25rem;
  height: 1.25rem;
  margin-top: -.625rem;
  margin-right: 1.5625rem;
  padding: 0;
  position: absolute;
  top: 50%;
  right: 0;
}

.choices[data-type*="select-one"] .choices__button:hover, .choices[data-type*="select-one"] .choices__button:focus {
  opacity: 1;
}

.choices[data-type*="select-one"] .choices__button:focus {
  box-shadow: 0 0 0 .125rem #fff;
}

.choices[data-type*="select-one"] .choices__item[data-value=""] .choices__button {
  display: none;
}

.choices[data-type*="select-one"]:after {
  content: "";
  pointer-events: none;
  border: .3125rem solid #0000;
  border-top-color: #495057;
  border: initial;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iNiIgdmlld0JveD0iMCAwIDEwIDYiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xLjY2Njc1IDEuMzMzOThMNS4wMDAwOCA0LjY2NzMyTDguMzMzNDEgMS4zMzM5OCIgc3Ryb2tlPSIjMDA0OTkzIiBzdHJva2Utd2lkdGg9IjEuNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=");
  background-repeat: no-repeat;
  width: .625rem;
  height: .375rem;
  margin: auto 0;
  transition: all .2s;
  position: absolute;
  top: 0;
  bottom: 0;
  right: .7188rem;
  transform: rotate(0);
}

.choices[data-type*="select-one"].is-open:after {
  transform: rotate(-180deg);
}

.choices[data-type*="select-one"][dir="rtl"]:after {
  left: .7188rem;
  right: auto;
}

.choices[data-type*="select-one"][dir="rtl"] .choices__button {
  margin-left: 1.5625rem;
  margin-right: 0;
  left: 0;
  right: auto;
}

.choices[data-type*="select-multiple"] .choices__inner, .choices[data-type*="text"] .choices__inner {
  cursor: text;
}

.choices[data-type*="select-multiple"] .choices__button, .choices[data-type*="text"] .choices__button {
  opacity: .75;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMjEiIHZpZXdCb3g9IjAgMCAyMSAyMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSIjRkZGIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0yLjU5Mi4wNDRsMTguMzY0IDE4LjM2NC0yLjU0OCAyLjU0OEwuMDQ0IDIuNTkyeiIvPjxwYXRoIGQ9Ik0wIDE4LjM2NEwxOC4zNjQgMGwyLjU0OCAyLjU0OEwyLjU0OCAyMC45MTJ6Ii8+PC9nPjwvc3ZnPg==");
  background-size: .5rem;
  border-left: .0625rem solid #1081e0;
  border-radius: 0;
  width: .5rem;
  margin: 0 -.25rem 0 .5rem;
  padding-left: 1rem;
  line-height: 1;
  display: inline-block;
  position: relative;
}

.choices[data-type*="select-multiple"] .choices__button:hover, .choices[data-type*="select-multiple"] .choices__button:focus, .choices[data-type*="text"] .choices__button:hover, .choices[data-type*="text"] .choices__button:focus {
  opacity: 1;
}

.choices__inner {
  vertical-align: top;
  background-color: #fff;
  border: .0625rem solid #0000;
  border-radius: .1563rem;
  width: 100%;
  min-height: 2.75rem;
  padding: .4688rem .4688rem .2344rem;
  font-size: 1rem;
  display: inline-block;
  overflow: hidden;
}

.is-focused .choices__inner, .is-open .choices__inner {
  border-color: #0000;
}

.is-open .choices__inner {
  border-radius: .1563rem .1563rem 0 0;
}

.is-flipped.is-open .choices__inner {
  border-radius: 0 0 .1563rem .1563rem;
}

.choices__list {
  color: #666;
  margin: 0;
  padding-left: 0;
  list-style: none;
}

.choices__list--single {
  width: 100%;
  padding: .25rem 1rem .25rem .25rem;
  display: inline-block;
}

[dir="rtl"] .choices__list--single {
  padding-left: 1rem;
  padding-right: .25rem;
}

.choices__list--single .choices__item {
  width: 100%;
}

.choices__list--multiple {
  display: inline;
}

.choices__list--multiple .choices__item {
  vertical-align: middle;
  color: #fff;
  word-break: break-all;
  box-sizing: border-box;
  background-color: #339af0;
  border: .0625rem solid #1b8eee;
  border-radius: 1.25rem;
  margin-bottom: .2344rem;
  margin-right: .2344rem;
  padding: .25rem .625rem;
  font-size: .875rem;
  font-weight: 400;
  display: inline-block;
}

.choices__list--multiple .choices__item[data-deletable] {
  padding-right: .3125rem;
}

[dir="rtl"] .choices__list--multiple .choices__item {
  margin-left: .2344rem;
  margin-right: 0;
}

.choices__list--multiple .choices__item.is-highlighted {
  background-color: #1b8eee;
  border: .0625rem solid #1081e0;
  color: #4d4d4d !important;
}

.is-disabled .choices__list--multiple .choices__item {
  background-color: #9facb9;
  border: .0625rem solid #8293a4;
}

.choices__list--dropdown, .choices__list[aria-expanded] {
  visibility: hidden;
  z-index: 10;
  word-break: break-all;
  will-change: visibility;
  background-color: #fff;
  border: .0625rem solid #0000;
  border-bottom-right-radius: .1563rem;
  border-bottom-left-radius: .1563rem;
  width: 100%;
  margin-top: .25rem;
  position: absolute;
  top: 100%;
  right: 0;
  overflow: hidden;
  box-shadow: 0 1rem 1.5rem -1rem #000;
}

.is-active.choices__list--dropdown, .is-active.choices__list[aria-expanded] {
  visibility: visible;
}

.is-open .choices__list--dropdown, .is-open .choices__list[aria-expanded] {
  border-color: #0000;
}

.is-flipped .choices__list--dropdown, .is-flipped .choices__list[aria-expanded] {
  border-radius: .25rem .25rem 0 0;
  margin-top: 0;
  margin-bottom: -.0625rem;
  top: auto;
  bottom: 100%;
}

.choices__list--dropdown .choices__list, .choices__list[aria-expanded] .choices__list {
  -webkit-overflow-scrolling: touch;
  will-change: scroll-position;
  max-height: 18.75rem;
  position: relative;
  overflow: auto;
}

.choices__list--dropdown .choices__item, .choices__list[aria-expanded] .choices__item {
  padding: .625rem;
  font-size: 1rem;
  position: relative;
}

[dir="rtl"] .choices__list--dropdown .choices__item, [dir="rtl"] .choices__list[aria-expanded] .choices__item {
  text-align: right;
}

@media (width >= 40rem) {
  .choices__list--dropdown .choices__item--selectable, .choices__list[aria-expanded] .choices__item--selectable {
    padding-right: 6.25rem;
  }

  .choices__list--dropdown .choices__item--selectable:after, .choices__list[aria-expanded] .choices__item--selectable:after {
    content: attr(data-select-text);
    opacity: 0;
    font-size: .875rem;
    position: absolute;
    top: 50%;
    right: .625rem;
    transform: translateY(-50%);
  }

  [dir="rtl"] .choices__list--dropdown .choices__item--selectable, [dir="rtl"] .choices__list[aria-expanded] .choices__item--selectable {
    text-align: right;
    padding-left: 6.25rem;
    padding-right: .625rem;
  }

  [dir="rtl"] .choices__list--dropdown .choices__item--selectable:after, [dir="rtl"] .choices__list[aria-expanded] .choices__item--selectable:after {
    left: .625rem;
    right: auto;
  }
}

.choices__list--dropdown .choices__item--selectable.is-highlighted, .choices__list[aria-expanded] .choices__item--selectable.is-highlighted {
  background-color: #f1f3f5;
}

.choices__list--dropdown .choices__item--selectable.is-highlighted:after, .choices__list[aria-expanded] .choices__item--selectable.is-highlighted:after {
  opacity: .5;
}

.choices__item {
  cursor: default;
}

.choices__item--selectable {
  cursor: pointer;
}

.choices__item--disabled {
  cursor: not-allowed;
  -webkit-user-select: none;
  user-select: none;
  opacity: .5;
}

.choices__heading {
  color: gray;
  border-bottom: .0625rem solid #1a1a1a00;
  padding: .625rem;
  font-size: .875rem;
  font-weight: 400;
}

.choices__button {
  text-indent: -624.938rem;
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;
  background-color: #0000;
  background-position: center;
  background-repeat: no-repeat;
  border: 0;
}

.choices__button:focus {
  outline: none;
}

.choices__input {
  vertical-align: baseline;
  background-color: #fff;
  border: 0;
  border-radius: 0;
  max-width: 100%;
  margin-bottom: .3125rem;
  padding: .25rem 0 .25rem .125rem;
  font-size: 1rem;
  display: inline-block;
}

.choices__input:focus {
  outline: 0;
}

.choices__input::-webkit-search-decoration {
  display: none;
}

.choices__input::-webkit-search-cancel-button {
  display: none;
}

.choices__input::-webkit-search-results-button {
  display: none;
}

.choices__input::-webkit-search-results-decoration {
  display: none;
}

.choices__input::-ms-clear {
  width: 0;
  height: 0;
  display: none;
}

.choices__input::-ms-reveal {
  width: 0;
  height: 0;
  display: none;
}

[dir="rtl"] .choices__input {
  padding-left: 0;
  padding-right: .125rem;
}

.choices__inner {
  color: #666;
  border-radius: .25rem;
  font-size: .875rem;
  font-weight: 400;
  border: .0625rem solid #e6e6e6 !important;
}

.choices__inner .choices__list--single {
  padding: .25rem 1.5rem .25rem .25rem;
}

.choices__placeholder {
  font-weight: 400;
  color: #666 !important;
}

.choices__list--dropdown {
  z-index: 5;
  background: #fff;
  border-radius: .25rem;
  min-width: max-content;
  border: .0625rem solid #e6e6e6 !important;
  box-shadow: 0 .5rem 1rem #e6e6e6 !important;
}

.choices__list--dropdown .choices__item {
  color: #666;
  font-weight: 400;
  font-size: .875rem !important;
}

.choices__list--dropdown .choices__item:after {
  display: none;
}

.select-container .choices__inner {
  align-items: center;
  display: flex;
}

.select-container.h-40 .choices__inner {
  height: 2.5rem;
}

.select-container.h-48 .choices__inner {
  height: 3rem;
}

.select-container.h-56 .choices__inner {
  height: 3.5rem;
}

.select-container.disabled {
  cursor: not-allowed;
  opacity: .3;
}

.select-container.disabled .choices {
  pointer-events: none;
  cursor: not-allowed;
}

.select-container.disabled .choices .choices__inner {
  pointer-events: none;
}

.secondary .choices__inner {
  background: #f8f9fa !important;
  border-color: #f1f3f5 !important;
}

.secondary .is-open .choices__inner {
  background: #fff !important;
  border-color: #e9ecef !important;
}

.tertiary .choices .choices__inner {
  border-radius: initial;
  border: initial !important;
  background: #f8f9fa !important;
  border-bottom: .0625rem solid #e9ecef !important;
}

.gridjs-head button, .gridjs-footer button {
  cursor: pointer;
  background-color: #0000;
  background-image: none;
  border: none;
  outline: none;
  margin: 0;
  padding: 0;
}

.gridjs-temp {
  position: relative;
}

.gridjs-head {
  width: 100%;
  margin-bottom: 5px;
  padding: 5px 1px;
}

.gridjs-head:after {
  content: "";
  clear: both;
  display: block;
}

.gridjs-head:empty {
  border: none;
  padding: 0;
}

.gridjs-container {
  color: #000;
  z-index: 0;
  padding: 2px;
  display: inline-block;
  position: relative;
  overflow: hidden;
}

.gridjs-footer {
  z-index: 5;
  background-color: #fff;
  border-color: #e5e7eb;
  border-top-style: solid;
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-radius: 0 0 8px 8px;
  width: 100%;
  padding: 12px 24px;
  display: block;
  position: relative;
  box-shadow: 0 1px 3px #0000001a, 0 1px 2px #00000042;
}

.gridjs-footer:empty {
  border: none;
  padding: 0;
}

input.gridjs-input {
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  border: 1px solid #d2d6dc;
  border-radius: 5px;
  outline: none;
  padding: 10px 13px;
  font-size: 14px;
  line-height: 1.45;
}

input.gridjs-input:focus {
  border-color: #9bc2f7;
  box-shadow: 0 0 0 3px #95bdf380;
}

.gridjs-pagination {
  color: #3d4044;
}

.gridjs-pagination:after {
  content: "";
  clear: both;
  display: block;
}

.gridjs-pagination .gridjs-summary {
  float: left;
  margin-top: 5px;
}

.gridjs-pagination .gridjs-pages {
  float: right;
}

.gridjs-pagination .gridjs-pages button {
  -webkit-user-select: none;
  user-select: none;
  background-color: #fff;
  border: 1px solid #d2d6dc;
  border-right: none;
  outline: none;
  padding: 5px 14px;
}

.gridjs-pagination .gridjs-pages button:focus {
  box-shadow: 0 0 0 2px #95bdf380;
}

.gridjs-pagination .gridjs-pages button:hover {
  color: #3c4257;
  background-color: #f7f7f7;
  outline: none;
}

.gridjs-pagination .gridjs-pages button:disabled, .gridjs-pagination .gridjs-pages button[disabled], .gridjs-pagination .gridjs-pages button:hover:disabled {
  cursor: default;
  color: #6b7280;
  background-color: #fff;
}

.gridjs-pagination .gridjs-pages button.gridjs-spread {
  cursor: default;
  box-shadow: none;
  background-color: #fff;
}

.gridjs-pagination .gridjs-pages button.gridjs-currentPage {
  background-color: #f7f7f7;
  font-weight: bold;
}

.gridjs-pagination .gridjs-pages button:last-child {
  border-right: 1px solid #d2d6dc;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.gridjs-pagination .gridjs-pages button:first-child {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}

button.gridjs-sort {
  float: right;
  cursor: pointer;
  background-color: #0000;
  background-position-x: center;
  background-repeat: no-repeat;
  background-size: contain;
  border: none;
  outline: none;
  width: 13px;
  height: 24px;
  margin: 0;
  padding: 0;
}

button.gridjs-sort-neutral {
  opacity: .3;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJDYXBhXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHdpZHRoPSI0MDEuOTk4cHgiIGhlaWdodD0iNDAxLjk5OHB4IiB2aWV3Qm94PSIwIDAgNDAxLjk5OCA0MDEuOTk4IiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA0MDEuOTk4IDQwMS45OTg7IgoJIHhtbDpzcGFjZT0icHJlc2VydmUiPgo8Zz4KCTxnPgoJCTxwYXRoIGQ9Ik03My4wOTIsMTY0LjQ1MmgyNTUuODEzYzQuOTQ5LDAsOS4yMzMtMS44MDcsMTIuODQ4LTUuNDI0YzMuNjEzLTMuNjE2LDUuNDI3LTcuODk4LDUuNDI3LTEyLjg0NwoJCQljMC00Ljk0OS0xLjgxMy05LjIyOS01LjQyNy0xMi44NUwyMTMuODQ2LDUuNDI0QzIxMC4yMzIsMS44MTIsMjA1Ljk1MSwwLDIwMC45OTksMHMtOS4yMzMsMS44MTItMTIuODUsNS40MjRMNjAuMjQyLDEzMy4zMzEKCQkJYy0zLjYxNywzLjYxNy01LjQyNCw3LjkwMS01LjQyNCwxMi44NWMwLDQuOTQ4LDEuODA3LDkuMjMxLDUuNDI0LDEyLjg0N0M2My44NjMsMTYyLjY0NSw2OC4xNDQsMTY0LjQ1Miw3My4wOTIsMTY0LjQ1MnoiLz4KCQk8cGF0aCBkPSJNMzI4LjkwNSwyMzcuNTQ5SDczLjA5MmMtNC45NTIsMC05LjIzMywxLjgwOC0xMi44NSw1LjQyMWMtMy42MTcsMy42MTctNS40MjQsNy44OTgtNS40MjQsMTIuODQ3CgkJCWMwLDQuOTQ5LDEuODA3LDkuMjMzLDUuNDI0LDEyLjg0OEwxODguMTQ5LDM5Ni41N2MzLjYyMSwzLjYxNyw3LjkwMiw1LjQyOCwxMi44NSw1LjQyOHM5LjIzMy0xLjgxMSwxMi44NDctNS40MjhsMTI3LjkwNy0xMjcuOTA2CgkJCWMzLjYxMy0zLjYxNCw1LjQyNy03Ljg5OCw1LjQyNy0xMi44NDhjMC00Ljk0OC0xLjgxMy05LjIyOS01LjQyNy0xMi44NDdDMzM4LjEzOSwyMzkuMzUzLDMzMy44NTQsMjM3LjU0OSwzMjguOTA1LDIzNy41NDl6Ii8+Cgk8L2c+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPC9zdmc+");
  background-position-y: center;
}

button.gridjs-sort-asc {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJDYXBhXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHdpZHRoPSIyOTIuMzYycHgiIGhlaWdodD0iMjkyLjM2MXB4IiB2aWV3Qm94PSIwIDAgMjkyLjM2MiAyOTIuMzYxIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAyOTIuMzYyIDI5Mi4zNjE7IgoJIHhtbDpzcGFjZT0icHJlc2VydmUiPgo8Zz4KCTxwYXRoIGQ9Ik0yODYuOTM1LDE5Ny4yODdMMTU5LjAyOCw2OS4zODFjLTMuNjEzLTMuNjE3LTcuODk1LTUuNDI0LTEyLjg0Ny01LjQyNHMtOS4yMzMsMS44MDctMTIuODUsNS40MjRMNS40MjQsMTk3LjI4NwoJCUMxLjgwNywyMDAuOTA0LDAsMjA1LjE4NiwwLDIxMC4xMzRzMS44MDcsOS4yMzMsNS40MjQsMTIuODQ3YzMuNjIxLDMuNjE3LDcuOTAyLDUuNDI1LDEyLjg1LDUuNDI1aDI1NS44MTMKCQljNC45NDksMCw5LjIzMy0xLjgwOCwxMi44NDgtNS40MjVjMy42MTMtMy42MTMsNS40MjctNy44OTgsNS40MjctMTIuODQ3UzI5MC41NDgsMjAwLjkwNCwyODYuOTM1LDE5Ny4yODd6Ii8+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPC9zdmc+");
  background-position-y: 35%;
  background-size: 10px;
}

button.gridjs-sort-desc {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJDYXBhXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHdpZHRoPSIyOTIuMzYycHgiIGhlaWdodD0iMjkyLjM2MnB4IiB2aWV3Qm94PSIwIDAgMjkyLjM2MiAyOTIuMzYyIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAyOTIuMzYyIDI5Mi4zNjI7IgoJIHhtbDpzcGFjZT0icHJlc2VydmUiPgo8Zz4KCTxwYXRoIGQ9Ik0yODYuOTM1LDY5LjM3N2MtMy42MTQtMy42MTctNy44OTgtNS40MjQtMTIuODQ4LTUuNDI0SDE4LjI3NGMtNC45NTIsMC05LjIzMywxLjgwNy0xMi44NSw1LjQyNAoJCUMxLjgwNyw3Mi45OTgsMCw3Ny4yNzksMCw4Mi4yMjhjMCw0Ljk0OCwxLjgwNyw5LjIyOSw1LjQyNCwxMi44NDdsMTI3LjkwNywxMjcuOTA3YzMuNjIxLDMuNjE3LDcuOTAyLDUuNDI4LDEyLjg1LDUuNDI4CgkJczkuMjMzLTEuODExLDEyLjg0Ny01LjQyOEwyODYuOTM1LDk1LjA3NGMzLjYxMy0zLjYxNyw1LjQyNy03Ljg5OCw1LjQyNy0xMi44NDdDMjkyLjM2Miw3Ny4yNzksMjkwLjU0OCw3Mi45OTgsMjg2LjkzNSw2OS4zNzd6Ii8+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPC9zdmc+");
  background-position-y: 65%;
  background-size: 10px;
}

button.gridjs-sort:focus {
  outline: none;
}

table.gridjs-table {
  border-collapse: collapse;
  text-align: left;
  table-layout: fixed;
  max-width: 100%;
  margin: 0;
  padding: 0;
  display: table;
  overflow: auto;
}

.gridjs-tbody {
  background-color: #fff;
}

td.gridjs-td {
  box-sizing: content-box;
  background-color: #fff;
  border: 1px solid #e5e7eb;
  padding: 12px 24px;
}

td.gridjs-td:first-child {
  border-left: none;
}

td.gridjs-td:last-child {
  border-right: none;
}

td.gridjs-message {
  text-align: center;
}

th.gridjs-th {
  color: #6b7280;
  -webkit-user-select: none;
  user-select: none;
  box-sizing: border-box;
  white-space: nowrap;
  vertical-align: middle;
  background-color: #f9fafb;
  border: 1px solid #e5e7eb;
  border-top: none;
  outline: none;
  padding: 14px 24px;
  position: relative;
}

th.gridjs-th .gridjs-th-content {
  text-overflow: ellipsis;
  float: left;
  width: 100%;
  overflow: hidden;
}

th.gridjs-th-sort {
  cursor: pointer;
}

th.gridjs-th-sort .gridjs-th-content {
  width: calc(100% - 15px);
}

th.gridjs-th-sort:hover, th.gridjs-th-sort:focus {
  background-color: #e5e7eb;
}

th.gridjs-th-fixed {
  position: sticky;
  box-shadow: 0 1px #e5e7eb;
}

@supports ((-moz-appearance: none)) {
  th.gridjs-th-fixed {
    box-shadow: 0 0 0 1px #e5e7eb;
  }
}

th.gridjs-th:first-child {
  border-left: none;
}

th.gridjs-th:last-child {
  border-right: none;
}

.gridjs-tr {
  border: none;
}

.gridjs-tr-selected td {
  background-color: #ebf5ff;
}

.gridjs-tr:last-child td {
  border-bottom: 0;
}

.gridjs *, .gridjs :after, .gridjs :before {
  box-sizing: border-box;
}

.gridjs-wrapper {
  z-index: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  border-color: #e5e7eb;
  border-top-width: 1px;
  border-radius: 8px 8px 0 0;
  width: 100%;
  display: block;
  position: relative;
  overflow: auto;
  box-shadow: 0 1px 3px #0000001a, 0 1px 2px #00000042;
}

.gridjs-wrapper:nth-last-of-type(2) {
  border-bottom-width: 1px;
  border-radius: 8px;
}

.gridjs-search {
  float: left;
}

.gridjs-search-input {
  width: 250px;
}

.gridjs-loading-bar {
  z-index: 10;
  opacity: .5;
  background-color: #fff;
  position: absolute;
  inset: 0;
}

.gridjs-loading-bar:after {
  content: "";
  background-image: linear-gradient(90deg, #ccc0 0, #ccc3 20%, #cccccc80 60%, #ccc0);
  animation: 2s infinite shimmer;
  position: absolute;
  inset: 0;
  transform: translateX(-100%);
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}

.gridjs-td .gridjs-checkbox {
  cursor: pointer;
  margin: auto;
  display: block;
}

.gridjs-resizable {
  width: 5px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
}

.gridjs-resizable:hover {
  cursor: ew-resize;
  background-color: #9bc2f7;
}

.filepond--root {
  margin-top: 8px;
  background: #fff !important;
  font-family: Be Vietnam Pro, sans-serif !important;
}

.filepond--credits {
  display: none;
}

.filepond--drip {
  opacity: 0 !important;
  background: none !important;
}

.filepond--panel-root {
  background-color: #0000;
}

.filepond--drop-label {
  cursor: pointer;
  border: 2px dashed #f8f9fa;
  border-radius: 8px;
  transition: background .3s;
  margin-bottom: 1rem !important;
}

.filepond--drop-label * {
  cursor: pointer;
}

.filepond--drop-label label {
  color: #495057;
  font-weight: 500;
}

.filepond--drop-label:hover, .filepond--drop-label:active {
  background: #ffffff1a;
}

.filepond--label-action {
  color: #339af0;
  -webkit-text-decoration: initial !important;
  text-decoration: initial !important;
}

.filepond--list-scroller {
  margin-top: 1rem !important;
}

.filepond--list {
  margin-top: 1rem !important;
  left: 0 !important;
  right: 0 !important;
}

.filepond--item {
  border-radius: 8px;
  height: 5rem !important;
}

.filepond--item .filepond--file-wrapper {
  background: linear-gradient(93.23deg, #339af0 0%, #75befa 29.06%, #53affa 58.17%);
  border-radius: 8px;
  height: 5rem !important;
}

.filepond--item .filepond--file {
  align-items: center;
}

.filepond--item .filepond--file .filepond--file-info-main {
  margin-bottom: 4px;
  font-size: .75rem;
  font-weight: 600;
  color: #f8f2fa !important;
}

.filepond--item .filepond--file .filepond--file-info-sub {
  font-size: .75rem;
  font-weight: 500;
  color: #ffffffde !important;
}

.filepond--file-action-button.filepond--action-remove-item {
  cursor: pointer;
}

.loader-net {
  perspective: 1000px;
  color: #023880;
  z-index: 10;
  border-radius: 50%;
  width: 5.625rem;
  height: 5.625rem;
  margin: auto;
  position: absolute;
  inset: 0;
  transform: rotateZ(45deg);
}

.loader-net-container {
  z-index: 999999;
  backdrop-filter: blur(10px);
  background: #ffffffda;
  width: 100%;
  height: 100%;
  margin: auto;
  position: fixed;
  inset: 0;
}

.loader-net:before, .loader-net:after {
  content: "";
  width: inherit;
  height: inherit;
  border-radius: 50%;
  animation: 1s linear infinite spin;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotateX(70deg);
}

.loader-net:after {
  color: #339af0;
  animation-delay: .4s;
  transform: rotateY(70deg);
}

@keyframes rotate {
  0% {
    transform: translate(-50%, -50%)rotateZ(0);
  }

  100% {
    transform: translate(-50%, -50%)rotateZ(360deg);
  }
}

@keyframes rotateccw {
  0% {
    transform: translate(-50%, -50%)rotate(0);
  }

  100% {
    transform: translate(-50%, -50%)rotate(-360deg);
  }
}

@keyframes spin {
  0%, 100% {
    box-shadow: .2em 0;
  }

  12% {
    box-shadow: .2em .2em;
  }

  25% {
    box-shadow: 0 .2em;
  }

  37% {
    box-shadow: -.2em .2em;
  }

  50% {
    box-shadow: -.2em 0;
  }

  62% {
    box-shadow: -.2em -.2em;
  }

  75% {
    box-shadow: 0 -.2em;
  }

  87% {
    box-shadow: .2em -.2em;
  }
}

.modal_padrao {
  --zindex-deep: 900;
  --bg-modal: white;
  background: var(--bg-modal);
  pointer-events: none;
  opacity: 0;
  visibility: hidden;
  will-change: trasform;
  z-index: var(--zindex-modal, 1000);
  border-radius: 4px;
  transition: opacity .4s, transform .4s, visibility .4s;
  position: fixed;
  box-shadow: 0 10px 60px #0000001a;
}

.modal_padrao[data-position="center"] {
  width: 500px;
  height: 500px;
  margin: auto;
  inset: 0;
  transform: translateY(30px);
}

.modal_padrao[data-position="center"] .modal--content {
  transform: translateX(0);
}

.modal_padrao[data-position="right"] {
  border-left: 1px solid #0000001c;
  width: 600px;
  height: 100vh;
  top: 0;
  right: 0;
  transform: translateX(100%);
}

.modal_padrao[data-position="left"] {
  border-right: 1px solid #0000001c;
  width: 600px;
  height: 100vh;
  top: 0;
  left: 0;
  transform: translateX(-100%);
}

.modal_padrao[data-position="bottom"] {
  border: 1px solid #0000001c;
  border-bottom: initial;
  border-radius: 8px 8px 0 0;
  width: 98vw;
  height: 400px;
  margin: 0 auto;
  bottom: 0;
  left: 0;
  right: 0;
  transform: translateY(100%);
}

.modal_padrao[data-position="top"] {
  border: 1px solid #0000001c;
  border-top: initial;
  border-radius: 0 0 8px 8px;
  width: 98vw;
  height: 400px;
  margin: 0 auto;
  top: 0;
  left: 0;
  right: 0;
  transform: translateY(100%);
}

.modal_padrao .modal--content {
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  background-color: #fff;
  max-height: 0;
  transition: opacity .3s, transform .3s, visibility .3s;
  transform: translateX(20px);
}

.modal_padrao .modal--content.active {
  opacity: 1;
  visibility: visible;
  pointer-events: initial;
  max-height: initial;
  transform: translateX(0);
}

.modal_padrao--deep {
  z-index: var(--zindex-deep);
  pointer-events: none;
  visibility: hidden;
  width: 100vw;
  height: 100vh;
  transition: transform .3s ease-in-out, opacity .3s ease-in-out, visibility .3s ease-in-out, background .2s ease-in-out;
  position: fixed;
  top: 0;
  left: 0;
}

.modal_padrao--deep.active {
  pointer-events: initial;
  visibility: visible;
  cursor: pointer;
  background: #0000004d;
}

.modal_padrao.active {
  pointer-events: all;
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.modal_padrao.active[data-position="center"] {
  transform: translateY(0);
}

.modal_padrao.active[data-position="right"], .modal_padrao.active[data-position="left"] {
  transform: translateX(0);
}

.modal_padrao.active[data-position="bottom"] {
  transform: translateY(0);
}
/*# sourceMappingURL=style.css.map */
