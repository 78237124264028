@import 'colors';

td.gridjs {
  &-td {
    border: 1px solid $gray3;
    padding: 12px 24px;
    background-color: $white;
    box-sizing: content-box;
  }

  &-td:first-child {
    border-left: none;
  }

  &-td:last-child {
    border-right: none;
  }

  &-message {
    text-align: center;
  }
}
