.gridjs {
  &-head,
  &-footer {
    button {
      cursor: pointer;
      background-color: transparent;
      background-image: none;
      padding: 0;
      margin: 0;
      border: none;
      outline: none;
    }
  }
}
