@import 'colors';

.gridjs {
  &-loading-bar {
    z-index: 10;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: $white;
    opacity: 0.5;

    &::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transform: translateX(-100%);
      background-image: linear-gradient(
        90deg,
        rgba(#ccc, 0) 0,
        rgba(#ccc, 0.2) 20%,
        rgba(#ccc, 0.5) 60%,
        rgba(#ccc, 0)
      );
      animation: shimmer 2s infinite;
      content: '';
    }

    @keyframes shimmer {
      100% {
        transform: translateX(100%);
      }
    }
  }
}
