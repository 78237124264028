@import 'colors';

th.gridjs {
  &-th {
    position: relative;
    color: $gray5;
    background-color: $gray1;
    border: 1px solid $gray3;
    border-top: none;
    padding: 14px 24px;
    user-select: none;
    box-sizing: border-box;
    white-space: nowrap;
    outline: none;
    vertical-align: middle;

    .gridjs-th-content {
      text-overflow: ellipsis;
      overflow: hidden;
      width: 100%;
      float: left;
    }

    &-sort {
      cursor: pointer;

      .gridjs-th-content {
        width: calc(100% - 15px);
      }
    }

    &-sort:hover {
      background-color: $gray3;
    }

    &-sort:focus {
      background-color: $gray3;
    }

    &-fixed {
      position: sticky;
      box-shadow: 0 1px 0 0 $gray3;

      @supports (-moz-appearance: none) {
        box-shadow: 0 0 0 1px $gray3;
      }
    }
  }

  &-th:first-child {
    border-left: none;
  }

  &-th:last-child {
    border-right: none;
  }
}
