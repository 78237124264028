/* ===============================
=            Choices            =
=============================== */

@use "sass:color";

$choices-selector: "choices" !default;
$choices-font-size-lg: 1rem !default;
$choices-font-size-md: .875rem !default;
$choices-font-size-sm: .75rem !default;
$choices-guttering: 1.5rem !default;
$choices-border-radius: .1563rem !default;
$choices-border-radius-item: 1.25rem !default;
$choices-bg-color: #f9f9f9 !default;
$choices-bg-color-disabled: #eaeaea !default;
$choices-bg-color-dropdown: #fff !default;
$choices-text-color: #333 !default;
$choices-keyline-color: #ddd !default;
$choices-primary-color: #004993 !default;
$choices-disabled-color: #eaeaea !default;
$choices-highlight-color: $neutral-20 !default;
$choices-button-dimension: .5rem !default;
$choices-button-offset: .5rem !default;

//////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////

$choices-selector: "choices";
$choices-font-size-lg: 1rem;
$choices-font-size-md: 1rem;
$choices-font-size-sm: .875rem;
$choices-guttering: 1.5rem;
$choices-border-radius: .1563rem;
$choices-border-radius-item: 1.25rem;
$choices-bg-color: $white;
$choices-bg-color-disabled: $white;
$choices-bg-color-dropdown: $white;
$choices-text-color: $neutral-80;
$choices-keyline-color: transparent;
$choices-primary-color: $primary-pure;
$choices-disabled-color: $neutral-30;
$choices-highlight-color: $white;
$choices-button-dimension: .5rem;
$choices-button-offset: .5rem;
$choices-dropdown-shadow: 0rem 1rem 1.5rem -1rem #000000;
$choices-highlight-bg: $neutral-20;
$choices-icon-cross: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMjEiIHZpZXdCb3g9IjAgMCAyMSAyMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSIjRkZGIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0yLjU5Mi4wNDRsMTguMzY0IDE4LjM2NC0yLjU0OCAyLjU0OEwuMDQ0IDIuNTkyeiIvPjxwYXRoIGQ9Ik0wIDE4LjM2NEwxOC4zNjQgMGwyLjU0OCAyLjU0OEwyLjU0OCAyMC45MTJ6Ii8+PC9nPjwvc3ZnPg==");

$choices-icon-cross-inverse: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMjEiIHZpZXdCb3g9IjAgMCAyMSAyMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSIjMDAwIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0yLjU5Mi4wNDRsMTguMzY0IDE4LjM2NC0yLjU0OCAyLjU0OEwuMDQ0IDIuNTkyeiIvPjxwYXRoIGQ9Ik0wIDE4LjM2NEwxOC4zNjQgMGwyLjU0OCAyLjU0OEwyLjU0OCAyMC45MTJ6Ii8+PC9nPjwvc3ZnPg==");
$choices-z-index: 10;

.#{$choices-selector} {
	position: relative;
	overflow: hidden;
	margin-bottom: $choices-guttering;
	font-size: $choices-font-size-lg;

	&:focus {
		outline: none;
	}

	&:last-child {
		margin-bottom: 0;
	}

	&.is-open {
		overflow: visible;
	}

	&.is-disabled {
		.#{$choices-selector}__inner,
		.#{$choices-selector}__input {
			background-color: $choices-bg-color-disabled;
			cursor: not-allowed;
			user-select: none;
		}

		.#{$choices-selector}__item {
			cursor: not-allowed;
		}
	}

	[hidden] {
		display: none !important;
	}
}

.#{$choices-selector}[data-type*="select-one"] {
	cursor: pointer;

	.#{$choices-selector}__inner {
		padding-bottom: .4688rem;
	}

	.#{$choices-selector}__input {
		display: block;
		width: 100%;
		padding: .625rem;
		border-bottom: .0625rem solid $choices-keyline-color;
		background-color: #fff;
		margin: 0;
	}

	.#{$choices-selector}__button {
		background-image: $choices-icon-cross-inverse;
		padding: 0;
		background-size: .5rem;
		position: absolute;
		top: 50%;
		right: 0;
		margin-top: -0.625rem;
		margin-right: 1.5625rem;
		height: 1.25rem;
		width: 1.25rem;
		border-radius: 10em;
		opacity: 0.25;

		&:hover,
		&:focus {
			opacity: 1;
		}

		&:focus {
			box-shadow: 0 0 0 .125rem $choices-highlight-color;
		}
	}

	.#{$choices-selector}__item[data-value=""] .#{$choices-selector}__button {
		display: none;
	}

	&::after {
		content: "";
		height: .375rem;
		width: .625rem;
		border-style: solid;
		border-color: $choices-text-color transparent transparent transparent;
		border-width: .3125rem;
		position: absolute;
		right: .7188rem;
		top: 0;
		bottom: 0;
		margin: auto 0;
		pointer-events: none;

		border: initial;
		background-repeat: no-repeat;
		background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iNiIgdmlld0JveD0iMCAwIDEwIDYiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xLjY2Njc1IDEuMzMzOThMNS4wMDAwOCA0LjY2NzMyTDguMzMzNDEgMS4zMzM5OCIgc3Ryb2tlPSIjMDA0OTkzIiBzdHJva2Utd2lkdGg9IjEuNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=);
		transition: 0.2s ease;
		transform: rotate(0);
	}

	&.is-open::after {
		transform: rotate(-180deg);
	}

	&[dir="rtl"] {
		&::after {
			left: .7188rem;
			right: auto;
		}

		.#{$choices-selector}__button {
			right: auto;
			left: 0;
			margin-left: 1.5625rem;
			margin-right: 0;
		}
	}
}

.#{$choices-selector}[data-type*="select-multiple"],
.#{$choices-selector}[data-type*="text"] {
	.#{$choices-selector}__inner {
		cursor: text;
	}

	.#{$choices-selector}__button {
		position: relative;
		display: inline-block;
		margin-top: 0;
		margin-right: -$choices-button-offset * 0.5;
		margin-bottom: 0;
		margin-left: $choices-button-offset;
		padding-left: $choices-button-offset * 2;
		border-left: .0625rem solid color.adjust($choices-primary-color, $lightness: -10%);
		background-image: $choices-icon-cross;
		background-size: $choices-button-dimension;
		width: $choices-button-dimension;
		line-height: 1;
		opacity: 0.75;
		border-radius: 0;

		&:hover,
		&:focus {
			opacity: 1;
		}
	}
}

.#{$choices-selector}__inner {
	display: inline-block;
	vertical-align: top;
	width: 100%;
	background-color: $choices-bg-color;
	padding: .4688rem .4688rem .2344rem;
	border: .0625rem solid $choices-keyline-color;
	border-radius: $choices-border-radius;
	font-size: $choices-font-size-md;
	min-height: 2.75rem;
	overflow: hidden;

	.is-focused &,
	.is-open & {
		border-color: color.adjust($choices-keyline-color, $lightness: -15%);
	}

	.is-open & {
		border-radius: $choices-border-radius $choices-border-radius 0 0;
	}

	.is-flipped.is-open & {
		border-radius: 0 0 $choices-border-radius $choices-border-radius;
	}
}

.#{$choices-selector}__list {
	margin: 0;
	padding-left: 0;
	list-style: none;
	color: #666666;

	&[aria-expanded] {
		@extend %choices-dropdown;
	}
}

.#{$choices-selector}__list--single {
	display: inline-block;
	padding: .25rem 1rem .25rem .25rem;
	width: 100%;

	[dir="rtl"] & {
		padding-right: .25rem;
		padding-left: 1rem;
	}

	.#{$choices-selector}__item {
		width: 100%;
	}
}

.#{$choices-selector}__list--multiple {
	display: inline;

	.#{$choices-selector}__item {
		display: inline-block;
		vertical-align: middle;
		border-radius: $choices-border-radius-item;
		padding: .25rem .625rem;
		font-size: $choices-font-size-sm;
		font-weight: 400;
		margin-right: .2344rem;
		margin-bottom: .2344rem;
		background-color: $choices-primary-color;
		border: .0625rem solid color.adjust($choices-primary-color, $lightness: -5%);
		color: #fff;
		word-break: break-all;
		box-sizing: border-box;

		&[data-deletable] {
			padding-right: .3125rem;
		}

		[dir="rtl"] & {
			margin-right: 0;
			margin-left: .2344rem;
		}

		&.is-highlighted {
			background-color: color.adjust($choices-primary-color, $lightness: -5%);
			border: .0625rem solid color.adjust($choices-primary-color, $lightness: -10%);
			color: #4D4D4D !important;
		}

		.is-disabled & {
			background-color: color.adjust($choices-disabled-color, $lightness: -25%);
			border: .0625rem solid color.adjust($choices-disabled-color, $lightness: -35%);
		}
	}
}

%choices-dropdown {
	visibility: hidden;
	z-index: $choices-z-index;
	position: absolute;
	width: 100%;
	background-color: $choices-bg-color-dropdown;
	border: .0625rem solid $choices-keyline-color;
	top: 100%;
	right: 0;
	margin-top: .25rem;
	border-bottom-left-radius: $choices-border-radius;
	border-bottom-right-radius: $choices-border-radius;
	overflow: hidden;
	word-break: break-all;
	will-change: visibility;
	box-shadow: $choices-dropdown-shadow;

	&.is-active {
		visibility: visible;
	}

	.is-open & {
		border-color: color.adjust($choices-keyline-color, $lightness: -15%);
	}

	.is-flipped & {
		top: auto;
		bottom: 100%;
		margin-top: 0;
		margin-bottom: -0.0625rem;
		border-radius: 0.25rem 0.25rem 0 0;
	}

	.#{$choices-selector}__list {
		position: relative;
		max-height: 18.75rem;
		overflow: auto;
		-webkit-overflow-scrolling: touch;
		will-change: scroll-position;
	}

	.#{$choices-selector}__item {
		position: relative;
		padding: .625rem;
		font-size: $choices-font-size-md;

		[dir="rtl"] & {
			text-align: right;
		}
	}

	.#{$choices-selector}__item--selectable {
		@media (min-width: 40rem) {
			padding-right: 6.25rem;

			&::after {
				content: attr(data-select-text);
				font-size: $choices-font-size-sm;
				opacity: 0;
				position: absolute;
				right: .625rem;
				top: 50%;
				transform: translateY(-50%);
			}

			[dir="rtl"] & {
				text-align: right;
				padding-left: 6.25rem;
				padding-right: .625rem;

				&::after {
					right: auto;
					left: .625rem;
				}
			}
		}

		&.is-highlighted {
			background-color: $choices-highlight-bg;

			&::after {
				opacity: 0.5;
			}
		}
	}
}

.#{$choices-selector}__list--dropdown {
	@extend %choices-dropdown;
}

.#{$choices-selector}__item {
	cursor: default;
}

.#{$choices-selector}__item--selectable {
	cursor: pointer;
}

.#{$choices-selector}__item--disabled {
	cursor: not-allowed;
	user-select: none;
	opacity: 0.5;
}

.#{$choices-selector}__heading {
	font-weight: 400;
	font-size: $choices-font-size-sm;
	padding: .625rem;
	border-bottom: .0625rem solid color.adjust($choices-keyline-color, $lightness: 10%);
	color: color.adjust(#333, $lightness: 30%);
}

.#{$choices-selector}__button {
	text-indent: -624.9375rem;
	appearance: none;
	border: 0;
	background-color: transparent;
	background-repeat: no-repeat;
	background-position: center;
	cursor: pointer;

	&:focus {
		outline: none;
	}
}

.#{$choices-selector}__input {
	display: inline-block;
	vertical-align: baseline;
	background-color: $choices-bg-color;
	font-size: $choices-font-size-md;
	margin-bottom: .3125rem;
	border: 0;
	border-radius: 0;
	max-width: 100%;
	padding: .25rem 0 .25rem .125rem;

	&:focus {
		outline: 0;
	}

	&::-webkit-search-decoration,
	&::-webkit-search-cancel-button,
	&::-webkit-search-results-button,
	&::-webkit-search-results-decoration {
		display: none;
	}

	&::-ms-clear,
	&::-ms-reveal {
		display: none;
		width: 0;
		height: 0;
	}

	[dir="rtl"] & {
		padding-right: .125rem;
		padding-left: 0;
	}
}

.#{$choices-selector}__placeholder {
	// opacity: 0.5;
}

.choices__inner {
	border: .0625rem solid #E6E6E6 !important;
	border-radius: .25rem;
	font-size: 0.875rem;
	color: #666666;
	font-weight: 400;

	.choices__list--single {
		padding: .25rem 1.5rem .25rem .25rem;
	}
}
.choices__placeholder {
	color: #666666 !important;
	font-weight: 400;
}

.choices__list--dropdown {
	border: .0625rem solid #E6E6E6 !important;
	box-shadow: 0rem .5rem 1rem #E6E6E6 !important;
	background: $white;
	border-radius: .25rem;
	min-width: max-content;
	z-index: 5;
	.choices__item {
		font-size: 0.875rem !important;
			color: #666666;
		font-weight: 400;
		&:after {
			display: none;
		}
	}
}
.select-container {
	.choices__inner {
		display: flex;
		align-items: center;
	}
	&.h-40 {
		.choices__inner {
			height: 2.5rem;
		}
	}
	&.h-48 {
		.choices__inner {
			height: 3rem;
		}
	}
	&.h-56 {
		.choices__inner {
			height: 3.5rem;
		}
	}
	&.disabled {
		cursor: not-allowed;
		opacity: 0.3;
		.choices {
			pointer-events: none;
			cursor: not-allowed;
			.choices__inner {
				pointer-events: none;
			}
		}
	}
}
.secondary {
	.choices__inner {
		background: $neutral-10 !important;
		border-color: $neutral-20 !important;
	}
	.is-open .choices__inner {
		background: $white !important;
		border-color: $neutral-30 !important;
	}
}

.tertiary {
	.choices {
		.choices__inner {
			border: initial !important;
			border-bottom: .0625rem solid #e9ecef !important;
			border-radius: initial;
			background: #f8f9fa !important;
		}
	}
}

/* =====  End of Choices  ====== */
