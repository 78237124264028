@import 'colors';

.gridjs {
  &-footer {
    display: block;
    position: relative;
    width: 100%;
    z-index: 5;
    padding: 12px 24px;
    border-top: 1px solid $gray3;
    background-color: $white;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.26);
    border-radius: 0 0 8px 8px;
    border-bottom-width: 1px;
    border-color: $gray3;
  }

  &-footer:empty {
    padding: 0;
    border: none;
  }
}
